<template>
	<div
		class="panel"
		ref="panel"
		:style="panelStyle"
	>
		<div class="panel__title">
            <span
                v-for="(n, index) in value.title"
                :key="index"
                class="panel-font-back"
            >
				<span>{{ n }}</span>
			</span>
		</div>
		<div
			v-if="regularFlights.length"
			class="panel-flights"
		>
			<div class="panel-flights__header">
				<div class="panel-flights__cell name">Vols</div>
				<div class="panel-flights__cell departure">Dép.</div>
				<div class="panel-flights__cell destination">Destination</div>
				<div class="panel-flights__cell boarding">Embarquement</div>
			</div>
			<div
				v-for="(flight, index) in regularFlights"
				:key="index"
				class="panel-flights__row"
			>
				<div
					class="panel-flights__cell name cell-name"
				>
					<div class="flex">
						<span
							v-for="(n, index) in flight.name"
							:key="index"
							class="panel-font-back small"
						>
							<span>{{ n }}</span>
						</span>
					</div>
				</div>
				<div
					class="panel-flights__cell departure cell-base"
				>
					<div class="text-center w-full">{{ flight.departure }}</div>
				</div>
				<div
					class="panel-flights__cell destination cell-base"
				>
					<div class="w-full">{{ flight.destination }}</div>
				</div>
				<div
					class="panel-flights__cell boarding cell-base"
				>
					<div class="w-full">{{ flight.boarding }}</div>
				</div>
			</div>
		</div>
		<div
			v-if="punctualFlights.length"
			class="panel-flights regular"
		>
			<div class="panel-flights__header">
				<div class="panel-flights__cell name">Vols réguliers</div>
				<div class="panel-flights__cell boarding">Embarquement</div>
			</div>
			<div
				v-for="(flight, index) in punctualFlights"
				:key="index"
				class="panel-flights__row"
			>
				<div
					class="panel-flights__cell name cell-name"
				>
					<div class="flex">
						<span
							v-for="(n, index) in flight.name"
							:key="index"
							class="panel-font-back small"
						>
							<span>{{ n }}</span>
						</span>
					</div>
				</div>
				<div
					class="panel-flights__cell destination cell-base"
				>
					<div class="w-full">{{ flight.destination }}</div>
				</div>
				<div
					class="panel-flights__cell boarding cell-base"
				>
					<div class="w-full">{{ flight.boarding }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Panel',
	props: {
		value: Object
	},
	computed: {
		panelStyle() {
			if(!this.panelWidth)
				return;
			return `--panel-width: ${this.panelWidth}`
		},
		regularFlights() {
			return this.value.flights.filter((f) => f.type === 'regular')
		},
		punctualFlights() {
			return this.value.flights.filter((f) => f.type === 'punctual')
		}
	},
	data(){
		return {
			panelWidth: null,
		}
	},
	methods: {
		async export() {
			const el = this.$refs.panel;
			const options = {
				windowWidth: 1920,
				windowHeight: 1080,
				width: 1920,
				height: 1080,
			}
			const canvas = await this.$html2canvas(el, options)
			this.$emit('exported', canvas)
			// canvas.id = "canvas-clone";
			// document.body.appendChild(canvas);
			const link = document.createElement('a');
			let d = new Date(Date.now())

			link.download = `image-accueil-${d.getDate()}-${d.getMonth()+1}-${d.getFullYear()}-${d.getHours()}${d.getMinutes()}${d.getSeconds()}`;
			link.href = canvas.toDataURL('image/jpeg')
			link.click();
			// canvas.toBlob(function(blob) {
				// const newImg = document.createElement('img'),
				// 	url = URL.createObjectURL(blob);
				//
				// newImg.onload = function() {
				// 	// no longer need to read the blob so it's revoked
				// 	URL.revokeObjectURL(url);
				// };
				//
				// newImg.src = url;
				// document.body.appendChild(newImg);
			// }, 'image/jpeg', 1);
		}
	},
	mounted(){
		this.panelWidth = this.$refs.panel.clientWidth
		window.addEventListener('resize', () => {
			this.panelWidth = this.$refs.panel.clientWidth
		})
	}
}
</script>
