<template>
	<div class="flex w-full">
<!--		<div class="border-l bg-gray-50 border-gray-100 w-2/12">-->

<!--		</div>-->
		<div class="flex flex-col bg-gray-100 w-full">
			<div class="border-l border-gray-200 flex-grow w-full px-10 py-8">
				<div class="text-4xl font-medium font-serif mb-4">
					Génération du panneau d'accueil
				</div>
				<div class="grid grid-cols-1 gap-6">
					<label class="block">
						<span class="text-lg text-gray-700">Titre du panneau</span>
						<input
							v-model="panelData.title"
							:maxlength="panelOptions.title.max"
							class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
							placeholder="Titre du panneau"
							type="text"
						/>
						<span class="text-sm pt-1 block text-gray-400">Caractères maximum {{ panelData.title.length }}/{{ panelOptions.title.max }}</span>
					</label>
					<div>
						<p class="text-xl text-medium font-serif mb-2">Vols</p>
						<draggable
							v-model="panelData.flights"
							v-bind="dragOptions"
							class="editor-medias-controls-holder pr-3"
							group="medias"
							@start="drag=true"
						>
							<transition-group
								:name="!drag ? 'flip-list' : null"
								class="d-flex"
								type="transition"
							>
								<div
									v-for="(flight, flightIndex) in panelData.flights"
									:key="flightIndex+'-key'"
									class="flex items-center space-x-3 mb-2"
								>
									<div
										class="cursor-move text-gray-400 hover:text-gray-700 transition-all"
										v-tooltip="'Réordonner'"
									>
										<svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
											<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
										</svg>
									</div>
									<div
										v-for="(field, index) in fields.flight"
										:key="index"
										class="block"
										:class="field.class"
									>
										<span
											class="text-sm pr-3 mb-1 block whitespace-nowrap text-gray-700"
										>
											{{ field.type !== 'radio' ? field.label : '' }}
										</span>

										<div
											v-if="field.type === 'radio'"
											class="flex flex-col space-y-1 pt-3"
										>
											<label
												v-for="(option, index) in field.options"
												:key="index"
												:for="option.value+'_'+flightIndex"
												class="block transition-all cursor-pointer px-2 py-1 mt-1 bg-gray-800 bg-opacity-5 hover:bg-opacity-10 text-gray-500 h-full w-full rounded-md text-xs border-gray-300"
												:class="{'bg-light-blue-200 text-light-blue-600 bg-opacity-40 hover:bg-opacity-40 cursor-default': flight[field.name] === option.value}"
											>
												{{ option.label }}
												<input
													v-model="flight[field.name]"
													:value="option.value"
													type="radio"
													:id="option.value+'_'+flightIndex"
													class="absolute opacity-0 hidden"
												>
											</label>
										</div>
										<multiselect
											v-else-if="field.type === 'multiselect'"
											v-model="flight[field.name]"
											:options="field.options"
											@search-change="field.search = $event"
											selectLabel=""
											deselectLabel="Déselectionner"
											selectedLabel="Sélectionné"
											style="min-width: 150px;"
											placeholder="Choisissez"
										>
											<span
												@click="field.options.push(field.search)"
												slot="noResult"
											>
												Créer l'option <strong class="text-ligh-blue-700">{{ field.search }}</strong>
											</span>
										</multiselect>
										<input
											v-else
											v-model="flight[field.name]"
											class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
											:placeholder="field.placeholder"
											:type="field.type"
											:maxlength="field.rules.maxLength"
											:max="field.rules.max"
											:min="field.rules.min"
										/>
										<span
											v-if="field.rules.maxLength || field.rules[flight.type]"
											class="text-sm text-right pt-1 block text-gray-400"
										>{{ flight[field.name].length }}/{{ field.rules[flight.type] ? field.rules[flight.type].maxLength : field.rules.maxLength }}</span>
										<span v-else class="text-transparent">.&nbsp;</span>
									</div>
									<div
										@click="deleteFlight(flightIndex)"
										class="cursor-pointer w-6 h-6 items-center justify-center flex flex-shrink-0 text-gray-400 hover:text-red-600 hover:bg-red-200 rounded-full transition-all"
									>
										<svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
											<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
										</svg>
									</div>
								</div>
							</transition-group>
						</draggable>
						<button
							v-if="flightsLeft"
							@click="addFlight"
							class="transition-all hover:bg-opacity-20 flex items-center rounded-md bg-light-blue-700 bg-opacity-10 text-light-blue-700 text-sm font-medium px-4 py-2"
						>
							<svg class=" mr-2" width="12" height="20" fill="currentColor">
								<path fill-rule="evenodd" clip-rule="evenodd" d="M6 5a1 1 0 011 1v3h3a1 1 0 110 2H7v3a1 1 0 11-2 0v-3H2a1 1 0 110-2h3V6a1 1 0 011-1z"/>
							</svg>
							Ajouter un vol
						</button>
					</div>
				</div>
			</div>
			<div class="w-full bg-gray-900 mt-auto">
				<div class="bg-gray-800 px-5 pr-2 py-2 flex items-center justify-between">
					<span class="text-white text-opacity-50 text-sm">Aperçu du panneau</span>
					<button
						@click="$refs.panelGhost.export()"
						class="transition-all hover:bg-opacity-20 flex items-center rounded-md bg-white bg-opacity-10 text-white text-sm font-medium px-4 py-2"
					>
						<svg class=" mr-2" width="12" height="20" fill="currentColor">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M6 5a1 1 0 011 1v3h3a1 1 0 110 2H7v3a1 1 0 11-2 0v-3H2a1 1 0 110-2h3V6a1 1 0 011-1z"/>
						</svg>
						Générer l'image
					</button>
				</div>
				<div id="panel-preview">
					<div id="panel-preview--holder">
						<panel
							v-model="panelData"
							ref="panel"
						/>
						<div id="panel-ghost">
							<panel
								v-model="panelData"
								ref="panelGhost"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import draggable from 'vuedraggable'
import Panel from "@/components/Panel";

export default {
	name: 'App',
	components: {
		Panel,
		draggable
	},
	computed: {
		flightsLeft() {
			if ( (this.punctualFlights.length+this.regularFlights.length) < this.maxFlights) {
				return true
			}
			return false
		},
		maxFlights() {
			if (this.punctualFlights.length && this.regularFlights.length) {
				return 9
			}

			return 10
		},
		regularFlights() {
			return this.panelData.flights.filter((f) => f.type === 'regular')
		},
		punctualFlights() {
			return this.panelData.flights.filter((f) => f.type === 'punctual')
		}
	},
	watch: {
		panelData: {
			handler(newValue) {
				localStorage.setItem(this.storage.key, JSON.stringify(newValue))
			},
			deep: true
		}
	},
	data() {
		return {
			drag: true,
			storage: {
				key: 'mdc_data'
			},
			dragOptions: {
				animation: 200,
				group: "description",
				disabled: false,
				ghostClass: "ghost"
			},
			panelOptions: {
				title: {
					max: 47
				}
			},
			fields: {
				flight: [
					{
						label: 'Type',
						name: 'type',
						type: 'radio',
						class: '',
						rules: {},
						options: [
							{label: 'Vol', value: 'regular'},
							{label: 'Régulier', value: 'punctual'}
						]
					},
					{
						label: 'Nom de vol',
						name: 'name',
						type: 'text',
						placeholder: 'BC032',
						class: '',
						rules: {
							maxLength: 5
						}
					},
					{
						label: 'Heure de départ',
						name: 'departure',
						type: 'time',
						class: '',
						rules: {
							min: "09:00",
							max: "18:00"
						}
					},
					{
						label: 'Destination',
						name: 'destination',
						placeholder: 'Description',
						type: 'text',
						class: 'w-full',
						rules: {
							regular: {
								maxLength: 57
							},
							punctual: {
								maxLength: 100
							}
						}
					},
					{
						label: 'Embarquement',
						name: 'boarding',
						type: 'multiselect',
						class: 'flex-grow',
						rules: {},
						search: '',
						options: [
							'Laboratoire',
							'La boutique',
							'Le restaurant',
							'Musée',
						]
					}
				]
			},
			schemas: {
				flight: {
					'type': 'regular',
					'name': '',
					'departure': '10:00',
					'destination': '',
					'boarding': ''
				}
			},
			panelData: {
				title: 'Les_Secrets_du_chocolat',
				flights: [
					{
						'type': 'regular',
						'name': 'BC032',
						'departure': '10:00',
						'destination': 'Dégustation bière et chocolat - A la boutique',
						'boarding': ''
					},
					{
						'type': 'regular',
						'name': 'BC032',
						'departure': '10:00',
						'destination': 'Menu : crème asperge / boeuf Stroganoff / parfait chocolat',
						'boarding': ''
					},
					{
						'type': 'regular',
						'name': 'BC032',
						'departure': '10:00',
						'destination': 'Atelier tablette en chocolat',
						'boarding': ''
					},
					{
						'type': 'regular',
						'name': 'BC032',
						'departure': '10:00',
						'destination': 'Fermeture',
						'boarding': ''
					},
					{
						'type': 'punctual',
						'name': 'BC032',
						'departure': '10:00',
						'destination': 'Atelier tablette en chocolat > mercredis 16h, samedis 11h, dimanche 14h',
						'boarding': ''
					},
					{
						'type': 'punctual',
						'name': 'BC032',
						'departure': '10:00',
						'destination': '20% de réduction > du 16 au 26 mars',
						'boarding': ''
					},
					{
						'type': 'regular',
						'name': 'BC032',
						'departure': '10:00',
						'destination': '',
						'boarding': ''
					},
					{
						'type': 'regular',
						'name': 'BC032',
						'departure': '10:00',
						'destination': '',
						'boarding': ''
					},
					{
						'type': 'regular',
						'name': 'BC032',
						'departure': '10:00',
						'destination': '',
						'boarding': ''
					}
				]
			}
		}
	},
	methods: {
		deleteFlight(index) {
			this.panelData.flights.splice(index, 1)
		},
		addFlight() {
			this.$set(this.panelData.flights, this.panelData.flights.length, {
				'type': 'regular',
				'name': '',
				'departure': '10:00',
				'destination': '',
				'boarding': ''
			})
		}
	},
	mounted() {
		if (localStorage.getItem(this.storage.key)) {
			this.panelData = JSON.parse(localStorage.getItem(this.storage.key))
		}
	}
}
</script>